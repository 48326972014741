<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <CRow>
              <CCol md="4"> Dashboard Estimasi Permohonan </CCol>
              <CCol md="8" class="text-right"> </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol sm="12">
                <CRow>
                  <CCol sm="12">
                    <template>
                      <div>
                        <table class="table table-sm table-bordered">
                          <thead class="bg-dark text-white">
                            <tr>
                              <th>No.</th>
                              <th>Pelayanan</th>
                              <th v-for="(group, index) in group_roles" :key="index">
                                {{ group.group_name }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <template>
                              <tr v-if="loadingTable">
                                <td colspan="6">
                                  <i class="fa fa-spin fa-refresh"></i> Loading...
                                </td>
                              </tr>
                              <tr v-else-if="dataintable.length == 0">
                                <td colspan="6">Data Belum Tersedia</td>
                              </tr>
                              <tr v-for="(row_data, key_report) in dataintable" :key="key_report" v-else>
                                <td>{{ key_report + 1 }}</td>
                                <td>{{ row_data.Pelayanan }}</td>
                                <td v-for="group in group_roles" :key="group.id" class="text-center">
                                  <table>
                                    <tr>
                                      <td :style="getBackground('less_than_3_hours', row_data.roleGroups[group.group_name].less_than_3_hours || 0)">
                                        {{ row_data.roleGroups[group.group_name].less_than_3_hours || 0 }}
                                        <CIcon name="cil-file" />
                                      </td>
                                      <td :style="getBackground('between_1_and_3_days', row_data.roleGroups[group.group_name].between_1_and_3_days || 0)">
                                       {{ row_data.roleGroups[group.group_name].between_1_and_3_days || 0 }}
                                        <CIcon name="cil-file" />
                                      </td>
                                      <td :style="getBackground('more_than_4_days', row_data.roleGroups[group.group_name].more_than_4_days || 0)">
                                        {{ row_data.roleGroups[group.group_name].more_than_4_days || 0 }}
                                        <CIcon name="cil-file" />
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                    </template>
                  </CCol>
                </CRow>
                <CRow>
<div>
  <div>
    <CRow>
      <CCol sm="12">
        <CRow>
          <!-- Kolom untuk Tabel Warna Status -->
          <CCol md="6">
            <table class="table table-sm table-bordered">
              <thead>
                <tr>
                  <th>Status Warna</th>
                  <th>Keterangan</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td> </td>
                  <td>Tidak ada</td>
                </tr>
                <tr>
                  <td style="background-color: #d4edda;"></td>
                  <td>0 Hari (kurang dari 24 jam)</td>
                </tr>
                <tr>
                  <td style="background-color: #fff3cd;"></td>
                  <td>1 - 3 Hari</td>
                </tr>
                <tr>
                  <td style="background-color: #f8d7da;"></td>
                  <td>4 Hari dst</td>
                </tr>
              </tbody>
            </table>
          </CCol>

          <!-- Kolom untuk Menampilkan Waktu dan Tanggal -->
          <CCol md="6">
            <div class="center">
              <h3 class="text-center">STATUS<br/>PERMOHONAN</h3>
              <h3>{{ formattedTime }}</h3>
              <p>{{ formattedDate }}</p>
              <span style="font-size:10px">* Data akan diperbaharui setiap 10 menit</span>
            </div>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  </div>
</div>

                </CRow>
              </CCol>
            </CRow>
            <br />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
const dataintable = [];
import moment from 'moment';
import 'moment/locale/id';
export default {
  name: "DashboardEstimasiPermohonan",
  components: {},
  setup() {
    return {
      icon,
    };
  },
  data() {
    return {
      dataintable: [],
      paging: [],
      loadingTable: true,
      show_tables: false,
      session_role_id: JSON.parse(this.session).roles_id,
      session_data: JSON.parse(this.session),
      group_roles: [],
       currentDate: moment()
    };
  },
  methods: {
    getRoleGroup() {
      axios
        .get(this.apiLink + "api/crud/role-group", {
          headers: {
            Authorization: "Bearer " + this.access_token,
          },
        })
        .then((response) => {
          var res_daerah = response.data;
          var res_daerah_data = res_daerah.data[0];
          this.group_roles = res_daerah_data;
        });
    },

    fetchReport() {
      this.loadingTable = true;
      axios
        .get(this.apiLink + "api/crud/estimasi-waktu/report", {
          headers: {
            Authorization: "Bearer " + this.access_token,
          },
        })
        .then((response) => {
          var res_daerah = response.data;
          this.dataintable = res_daerah;
          this.loadingTable = false;
        });
    },

/*
    getBackground(roleGroup) {
      const { less_than_3_hours, between_1_and_3_days, more_than_4_days } =
        roleGroup;
      if (
        less_than_3_hours > between_1_and_3_days &&
        less_than_3_hours > more_than_4_days
      ) {
        return { backgroundColor: "#d4edda" }; // green
      } else if (
        between_1_and_3_days > less_than_3_hours &&
        between_1_and_3_days > more_than_4_days
      ) {
        return { backgroundColor: "#fff3cd" }; // yellow
      } else if (
        more_than_4_days > less_than_3_hours &&
        more_than_4_days > between_1_and_3_days
      ) {
        return { backgroundColor: "#f8d7da" }; // red
      } else if (
        less_than_3_hours === 0 &&
        between_1_and_3_days === 0 &&
        more_than_4_days === 0
      ) {
        return { backgroundColor: "white" }; // white
      } else {
        return { backgroundColor: "none" }; // default
      }
    },
    */
    getBackground(kualifikasiType, value) {
        // Apply default colors even if there's no data
        switch (kualifikasiType) {
          case 'less_than_3_hours':
            return { backgroundColor: value > 0 ? "#d4edda" : "#d4edda" }; // green or light gray
          case 'between_1_and_3_days':
            return { backgroundColor: value > 0 ? "#fff3cd" : "#fff3cd" }; // yellow or light gray
          case 'more_than_4_days':
            return { backgroundColor: value > 0 ? "#f8d7da" : "#f8d7da" }; // red or light gray
          default:
            return { backgroundColor: "white" }; // white
        }
      }
  },

  mounted() {
    $(".no-readonly").prop("readonly", false);

    this.getRoleGroup();
    this.fetchReport();

     this.interval = setInterval(() => {
      this.currentDate = moment();
     }, 1000);

      this.intervalReport = setInterval(this.fetchReport, 600000);
  },

  computed: {
   formattedTime() {
      return this.currentDate.format('HH:mm:ss');  // Format untuk menampilkan jam
    },
    formattedDate() {
      return this.currentDate.format('dddd, D MMMM YYYY');  // Format untuk menampilkan tanggal
    }
  },
    beforeDestroy() {
      clearInterval(this.interval);
    clearInterval(this.intervalReport);
  },
};
</script>
<style scoped>
.cell-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
}

.cell-item {
  display: flex;
  align-items: center;
  gap: 4px;
}

.cell-item CIcon {
  margin-left: 4px;
}
